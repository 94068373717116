<template>
  <div style="width:100%; display:block;">
    <span style="float:left; margin-left: 10px;" class="check">
      <input v-if="pageType ==='qna'" :id="idx" class="normal-checkbox" type="checkbox" :data="idx" :checked=this.checked @click="click({type:'checkbox'})"/>
    </span>
    <span style="float:left;margin:5px;">
      <label :for="idx" >idx {{idx}}</label>
    </span>
    <label style="float: right;margin:5px;">{{ orderType }}</label>
    <div class="container" >
      <table style="width:100%;">
        <td class="img-td" style="width:30%; vertical-align: middle;text-align: center;">
          <img v-if="imgUrl === undefined || imgUrl === ''" src="../../assets/logo_big.jpg" alt="geek9.kr">
          <img v-else :src="imgUrl" alt="geek9.kr">
          <br><br>
          <span v-if="invoiceNumber.indexOf('http') !== -1 && (status === '배송중' || status === '거래완료')">
            <a v-if="clearanceNumber !== '' && deliveryForeignCompany === '포스트고' && deliveryKrCompany === '우체국'" :href='postgoWoo + clearanceNumber' target="_blank">통관조회</a> 
            <a v-if="clearanceNumber !== '' && deliveryForeignCompany === '월드익스프레스' && deliveryKrCompany === '우체국'" :href='wel + clearanceNumber' target="_blank">통관조회</a> 
            <a v-if="clearanceNumber !== '' && deliveryForeignCompany === '아이포터' " :href='iporter + clearanceNumber' target="_blank">통관조회</a>
            <a v-if="clearanceNumber !== '' && deliveryForeignCompany === '포스트고' && deliveryKrCompany === 'CJ대한통운'" :href='postgoCj + clearanceNumber' target="_blank">통관조회</a>
            <a v-if="clearanceNumber !== '' && deliveryForeignCompany === '엘덱스' && deliveryKrCompany === 'CJ대한통운'" :href='eldex + clearanceNumber' target="_blank">통관조회</a>
          </span>
          <span v-else-if="invoiceNumber.indexOf('http') === -1 && (status === '배송중' || status === '거래완료')" >
            <a v-if="clearanceNumber !== '' && deliveryForeignCompany === '포스트고' && deliveryKrCompany === '우체국'" :href='postgoWoo + clearanceNumber' target="_blank">통관조회</a> 
            <a v-if="clearanceNumber !== '' && deliveryForeignCompany === '월드익스프레스' && deliveryKrCompany === '우체국'" :href='wel + clearanceNumber' target="_blank">통관조회</a> 
            <a v-if="clearanceNumber !== '' && deliveryForeignCompany === '아이포터' " :href='iporter + clearanceNumber' target="_blank">통관조회</a>
            <a v-if="clearanceNumber !== '' && deliveryForeignCompany === '포스트고' && deliveryKrCompany === 'CJ대한통운'" :href='postgoCj + clearanceNumber' target="_blank">통관조회</a>
            <a v-if="clearanceNumber !== '' && deliveryForeignCompany === '엘덱스' && deliveryKrCompany === 'CJ대한통운'" :href='eldex + clearanceNumber' target="_blank">통관조회</a>
          </span>
        </td>
        <td style="max-width:70%;">
          <table class="dynamic-table" style="width:100%;">
            <tr style="height: 100px;">
              <td>
                <table class="order_sub_table" style="table-layout:fixed;">
                  <tbody style="text-align: left; ">
                    <tr style="height: 30px;">
                      <td class="container_sub_style">주문일 : {{this.api.getDateByFormat(orderDate, 'YYYY.MM.DD')}}</td>
                      <td class="container_sub_style">현지가 : {{fprice}}</td>
                    </tr>
                    <tr style="height: 30px;">
                      <td class="container_sub_style">
                        구매가 : <span>{{ wonPrice === null ? '미정' : parseInt(wonPrice).toLocaleString() + '원'}}</span>
                        <span class="pay-blink pay-info" v-if="this.wonPrice > 0 && this.status === '입금대기' && orderType === '구매대행'">&nbsp;입금대기중 </span>
                      </td>
                      <td class="container_sub_style">구매국가 : {{country}}</td>
                    </tr>
                    <tr v-if="this.api.getCookies('page') === 'list'" style="height: 30px;">
                      <td class="container_sub_style" style="width: auto; overflow: hidden; max-lines: 1;">상품명 : {{name}}</td>
                      <td class="container_sub_style">구매량 : {{amount}} 개</td>
                    </tr>                      
                    <tr style="height: 30px;">
                      <td class="container_sub_style" >진행상태 : 
                        <span v-if="this.status === '거래취소'" style="font-weight: bold;">{{status}} <label v-if="!(notice === null || notice === undefined || notice == '' || notice.length > 15)" style="font-weight: normal;color:red;"> ({{ notice }})</label></span> 
                        <span v-else-if="this.status === '환불완료'" style="font-weight: bold;">{{status}} <label v-if="!(msg === null || msg === undefined || msg == '' || msg.length > 15)" style="font-weight: normal;color:red;"> ({{ msg }})</label></span> 
                        <span v-else style="font-weight: bold;"> {{status}}</span> 
                        <input v-if="this.status === '배송중'" class="inner_btn" @click="click({type:'cmpl'})" value="수취완료">
                      </td>
                      <td class="container_sub_style"><a :href="url" target="_blank">상품링크</a></td>
                    </tr>
                    <tr style="height: 30px;">
                      <td class="container_sub_style" >
                        주문번호 : <br>
                        <input ref="innerOrderNumber" class="inner_text" type="text" :value="orderNumber" />
                        <input class="inner_btn" @click="click({type:'orderNumber'})" v-if="orderNumber === undefined || orderNumber === ''" type="button" value="입력" :disabled="this.api.getOrderStatus(this.status) > 4 || this.api.getOrderStatus(this.status) < 0"/>
                        <input class="inner_btn" @click="click({type:'orderNumber'})" v-else type="button" value="수정" :disabled="this.api.getOrderStatus(this.status) > 4 || this.api.getOrderStatus(this.status) < 0" />
                      </td>
                      <td class="container_sub_style" >
                        트래킹넘버 : <br>
                        <input ref="innerTrackingNumber" class="inner_text" type="text" :value="trackingNumber" />
                        <input class="inner_btn" @click="click({type:'trackingNumber'})" v-if="trackingNumber === undefined || trackingNumber === ''" type="button" value="입력" :disabled="this.api.getOrderStatus(this.status) > 4 || this.api.getOrderStatus(this.status) < 0"/>
                        <input class="inner_btn" @click="click({type:'trackingNumber'})" v-else type="button" value="수정" :disabled="this.api.getOrderStatus(this.status) > 4 || this.api.getOrderStatus(this.status) < 0"/>
                      </td>
                    </tr>                      
                    <tr style="height: 30px;">
                      <td class="container_sub_style" v-if="invoiceNumber.indexOf('index.html') !== -1 && (status === '배송중' || status === '거래완료')" >
                        송장번호 : <a :href="invoiceNumber" target="_blank">{{ clearanceNumber }}</a> 
                      </td>
                      <td class="container_sub_style" v-else-if="invoiceNumber.indexOf('http') !== -1 && (status === '배송중' || status === '거래완료')" >
                        송장번호 : <a :href="invoiceNumber" target="_blank">{{ invoiceNumber.split('/')[invoiceNumber.split('/').length-1] }}</a> 
                      </td>
                      <td class="container_sub_style" v-else-if="invoiceNumber.indexOf('http') === -1 && (status === '배송중' || status === '거래완료')" >
                        송장번호 : <a :href="this.api.getDeliveryUrl(invoiceNumber, deliveryKrCompany)" target="_blank">{{ invoiceNumber }}</a>
                      </td>
                      <td class="container_sub_style" >
                        <input v-if="this.status === '배송중'" class="inner_btn" @click="click({type:'review', idx:idx, name:name, url:url, country:country, imgUrl:imgUrl, fPrice:fprice, orderType:orderType})" value="후기작성">
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </table>
      <div v-if="this.orderType ==='배송대행' && this.status === '주문접수'" class="no-result">주문번호와 트래킹넘버를 입력해 주셔야 도착 확인이 됩니다</div>
      <div v-if="this.wonPrice > 0 && this.status === '입금대기' && orderType === '구매대행'" class="no-result">
        <p>{{parseInt(wonPrice).toLocaleString()}}원을 입금해주시면 구매 진행합니다!!</p>
        <p>대행비 포함된 금액이고, 한국으로 보내는 배송비는 별도입니다.</p>
        <p>배송비는 GEEK9 창고에 물건 도착하면 그때 요청 드립니다.</p>
        <p>배송 관련 내용은 <a href="https://geek9.kr/faq" target="_blank">geek9.kr/faq</a>에 설명되어 있습니다.</p>
        <p>카카오뱅크 3333-24-9473287 하나됨(배성호)</p>
        <select v-model="pay" v-on:change="paySelected()" style="padding:5px;"> 
          <option value = "">간편결제선택</option>
          <option value = "kakao_pay_link" v-if="isMobile">카카오페이(모바일)</option>
          <option value = "kakao_pay_qr">카카오페이(QR)</option>
          <option value = "toss_pay_qr">토스(QR)</option>
        </select>
      </div>
      <div v-if="this.deliveryPrice2 > 0 && this.api.getOrderStatus(this.status) < 6 && this.api.getOrderStatus(this.status) > -1 && (''+progress).endsWith('1')" class="no-result">
        <p>배송비 결제가 확인되었습니다!</p>
        <p>감사합니다.</p>
      </div>
      <div v-if="this.status === '거래취소'" class="no-result">
        <p>아래의 사유 중에 하나로 거래가 취소되었습니다.</p>
        <p>1. 해외 판매자가 일방적으로 거래를 취소</p>
        <p>2. 해외 판매자가 답변이 없어서 결제가 취소</p>
        <p>3. 구매자 님의 요청으로 인한 취소</p>
        <p>해외 판매자에게 결제한 내역이 취소되면 환불해 드립니다.</p>
        <p>해외 결제 취소 확인 및 환불까지는 최소 한 달 이상 걸립니다.</p>
        <p>게시판에 주문하신 분 성함과 계좌번호를 남겨주세요!</p>
      </div>
    </div> 
  </div>
  <div id="payModal" class="modal">
    <span class="close">&times;</span>
    <img class="modal-content" id="payImg" alt="geek9.kr">
    <div id="payCaption"></div>
  </div>
</template>
<script>
import { ref } from 'vue'
import router from '@/router'

export default {
  emits: ["setValue"],
  setup() {
    const isMobile = ref(false)
    const userAgent = navigator.userAgent
    const mobileKeywords = ['Android', 'webOS', 'iPhone', 'iPad', 'iPod', 'BlackBerry', 'Windows Phone']
    isMobile.value = mobileKeywords.some(keyword => userAgent.includes(keyword))
    
    return {
      isMobile
    }
  },
  mounted() {
    if(this.deliveryPrice2 > 0) {
      this.$emit('setValue', {type:'deliveryPrice', deliveryPrice:this.deliveryPrice2, idx:this.deliveryIdx, status:this.status})
    }
  },
  data() {
    return {
      pay:'',
      iporter: 'http://www.epantos.com/ecp/web/pr/dt/popup/dlvChaseInqPopup.do?locale=ko&quickNo=',
      wel: "http://acieshop.com/pod.html?OrderNo=",
      eldex: "http://ex.korex.co.kr:7004/fis20/KIL_HttpCallExpTrackingInbound_Ctr.do?rqs_HAWB_NO=",
      postgoWoo: "https://acieshop.com/pod.php?OrderNo=",
      postgoCj: "https://www.doortodoor.co.kr/parcel/doortodoor.do?fsp_action=PARC_ACT_002&fsp_cmd=retrieveInvNoACT&invc_no="
    }
  },
  props: {
    idx: Number,
    uid: String,
    orderDate: String,
    fprice: String,
    wonPrice: String,
    url: String,
    imgUrl: String,
    country: String,
    trackingNumber: String,
    status: String,
    invoiceNumber: String,
    clearanceNumber: String,
    deliveryPrice2: Number,
    amount: Number,
    name: String,
    deliveryKrCompany: String,
    deliveryForeignCompany: String,
    deliveryForeignCompanyOrderId: String,
    orderType: String,
    orderNumber: String,
    checked: Boolean,
    progress: String,
    pageType: String,
    notice: String,
    msg: String,
    index: Number,
    deliveryIdx: Number
  },
  methods: {
    phref(type) {
      const el = document.getElementById(type)
      el.scrollIntoView()
      el.setAttribute('class', 'card main blink')
    },
    async click(param) {
      const type = param.type
      if(type === 'cmpl') {
        if(confirm('정상적으로 물건을 받으셨나요?')) {
          const id = this.api.getCookies('id')
          await this.api.axiosGql('SELECT_POINT_HISTORY', {type: 'SELECT_POINT_HISTORY', val: this.idx, id: id, pointType: '거래완료' })
          .then( async (res) => {
            this.api.axiosGql('UPDATE_ORDER_BY_IDX', {
              type: 'UPDATE_ORDER_BY_IDX',
              idx: this.idx,
              status: 7
            }).then( async (res2) => {
              if(res2.status === 200) {
                this.emitter.emit('reload', 'dashboard')
                this.$notify('거래완료 되었습니다.')
                if(res.data !== 'Dupliced in PointHistory') {
                  this.api.axiosGql('UPDATE_POINT', {type: 'UPDATE_POINT', id: this.api.getCookies('id'), point: 30} )
                  .then(async (res2) => {
                    const point = Number(res2.data.POINT)
                    // this.emitter.emit('setValue', point)
                    this.api.axiosGql('INSERT_POINT_HISTORY', {
                      type: 'INSERT_POINT_HISTORY',
                      id: this.api.getCookies('id'),
                      point: point,
                      pointType: '거래완료',
                      val: String(this.idx)
                    })
                  })
                } else {
                  this.$notify('이미 포인트를 지급받은 건입니다.')
                  alert('이미 포인트를 지급받은 건입니다.')
                }
              } else {
                this.$notify('다시 시도해주세요.')
              }
            })
          })
        }
      } else if(type === 'orderNumber') {
        if(confirm('주문번호를 변경하시겠습니까?')) {
          const orderNumber = this.$refs.innerOrderNumber.value
          this.api.axiosGql('UPDATE_ORDER_BY_IDX', {
            type: 'UPDATE_ORDER_BY_IDX',
            idx: this.idx,
            trackingNumber:this.trackingNumber,
            orderNumber: orderNumber,
            status: this.api.getOrderStatus(this.status)
          }).then( async (res) => {
            if(res.status === 200) {
              this.$emit('setValue', {type:type, orderNumber:orderNumber, idx:this.idx})
              this.$notify('주문번호가 변경되었습니다.')
              alert('주문번호가 변경되었습니다.')
              this.modifyHistory({type:type + '-' + this.idx, before:this.orderNumber, after:orderNumber})
            } else {
              this.$notify('다시 시도해주세요.')
              alert('다시 시도해주세요.')
            }
          })
        }
      } else if(type === 'trackingNumber') {
        if(confirm('트래킹넘버를 변경하시겠습니까?')) {
          const trackingNumber = this.$refs.innerTrackingNumber.value
          this.api.axiosGql('UPDATE_ORDER_BY_IDX', {
            type: 'UPDATE_ORDER_BY_IDX',
            idx: this.idx,
            trackingNumber: trackingNumber,
            orderNumber: this.orderNumber,
            status: this.api.getOrderStatus(this.status)
          }).then( async (res) => {
            if(res.status === 200) {
              this.$emit('setValue', {type:type, trackingNumber:trackingNumber, idx:this.idx})
              this.modifyHistory({type:type + '-' + this.idx, before:this.trackingNumber, after:trackingNumber})
              this.emitter.emit('confirm', {message: '트래킹넘버가 변경되었습니다.', header:'변경', icon:'pi pi-info-circle'})
            } else {
              this.emitter.emit('confirm', {message: '다시 시도해주세요.', header:this.api.getCookies('page'), icon:'pi pi-exclamation-triangle'})
            }
          })
          if(!(trackingNumber === undefined || this.deliveryForeignCompanyOrderId === undefined || this.deliveryForeignCompanyOrderId === '')) {
            const iporterResult = await this.api.axiosGql('SELECT_IPORTER_ORDER_DTL', {type:'SELECT_IPORTER_ORDER_DTL', orderId:this.deliveryForeignCompanyOrderId, trackingNumber:trackingNumber})
            const json = iporterResult.data
            if(json.order_status !== '' && json.status === 'success') {
              await this.api.axiosGql('IPORTER_API_TRACKING_URL', {type:'IPORTER_API_TRACKING_URL', order_id:this.deliveryForeignCompanyOrderId, tracking: trackingNumber})
            }
          }
        }
      } else if(type === 'review'){
        if(confirm('후기를 작성하시겠어요?')) {
          router.push({name:type, params:param})
        }
      } else if(type === 'checkbox') this.$emit("checkbox", {seq:this.index, idx:this.idx})
    },
    modifyHistory(param) {
      this.api.axiosGql('INSERT_ORDER_HISTORY', {type:'INSERT_ORDER_HISTORY', id: "" + this.api.getCookies('id'), uidBefore: param.before, uidAfter: param.after, content: param.type})
    },
    paySelected() {
      if(this.pay === '') return
      const payment = this.pay
      this.pay = ''
      if(payment === 'kakao_pay_link') window.open('https://qr.kakaopay.com/Ej79lhQuc', '_blank')
      else {
        let payModal = document.getElementById("payModal")
        let payImg = document.getElementById("payImg")
        let captionText = document.getElementById("payCaption")
        payModal.style.display = "block"
        
        var span = document.getElementsByClassName("close")[0]

        span.onclick = function() { 
          payModal.style.display = "none";
        }
        let imgSrc = ''
        let alt = ''
        if(payment === 'kakao_pay_qr') {
          imgSrc = 'https://geek9.kr/img/kakao_pay.46960bf8.png'
          alt = '카카오페이'
        } else if(payment === 'toss_pay_qr') {
          imgSrc = 'https://geek9.kr/img/toss_pay.41a340ba.png'
          alt = '토스'
        }
        payImg.src = imgSrc;
        captionText.innerHTML = alt;
      } 
    },
  }
}
</script>
<style scoped>
.pay-info {
  color: red;
}
.pay-blink {
  animation: blink-effect 1s step-end 99999;
}

.inner_btn {
  width:auto;
  font-size: 10px;
  background-color: lightsalmon;
  color: white;
  font-weight: bold;
  border-radius: 9px;
  border: 0px;
  padding: 5px;
  max-width: 50px;
  text-align: center;
  cursor: pointer;
  margin-left:10px;
  float:right;
}

.inner_text {
  max-width: 120px;
}
.modal {
  display: none; 
  position: fixed;
  z-index: 99999999; 
  padding-top: 100px; 
  left: auto; 
  top: 0;
  margin-left: -31px;
  width: 100%; 
  height: 100%;
  overflow: auto; 
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.9);
}
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

#payCaption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

.modal-content, #payCaption {  
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {-webkit-transform:scale(0)} 
  to {-webkit-transform:scale(1)}
}

@keyframes zoom {
  from {transform:scale(0)} 
  to {transform:scale(1)}
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

@media only screen and (max-width: 700px){
  .modal-content {
    width: 100%;
  }
}
</style>